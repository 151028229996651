import React from 'react';
import './Footer.css';
import darkcafeCup from '../assets/images/dark-cafe.png';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="footer-logo">
                    <img src={darkcafeCup} alt="Logo" />
                    <span>_cafe_ Holding Company</span>
                </div>
            </div>
            <div className="footer-columns">
                <div className="footer-section">
                    <h4>Social Media</h4>
                    <ul>
                        <li><a href="https://www.youtube.com/@Corbin_Brown" target="_blank" rel="noopener noreferrer">YouTube</a></li>
                        <li><a href="https://x.com/corbin_braun" target="_blank" rel="noopener noreferrer">X</a></li>
                        <li><a href="https://www.youtube.com/@corbinwander" target="_blank" rel="noopener noreferrer">(digital nomad)</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Investments</h4>
                    <ul>
                        <li><a href="https://webcafesoftware.com/" target="_blank" rel="noopener noreferrer">AI Marketplace</a></li>
                        <li><a href="https://bumpups.com/" target="_blank" rel="noopener noreferrer">Bumpups</a></li>
                        <li><a href="https://www.skool.com/ai-for-your-business/about" target="_blank" rel="noopener noreferrer">Skool</a></li>
                    </ul>
                </div>
                <div className="footer-section">
                    <h4>Education</h4>
                    <ul>
                        <li><a href="https://www.youtube.com/@Corbin_Brown/playlists" target="_blank" rel="noopener noreferrer">Content Catalog</a></li>
                        <li><a href="https://www.youtube.com/watch?v=-tnPCI5RdNA&list=PLJrzt4ameiaPTLN4LXiv1Y7ONKuaj7Q9O" target="_blank" rel="noopener noreferrer">Take This Website</a></li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Explore more ways to build—whether it's in the mountains, by the beach, or right in your apartment.</p>
                <div className="footer-border"></div>
                <div className="footer-links">
                    <div className="footer-legal">
                        <span>Copyright © 2025 Webcafe Commerce. All rights reserved.</span>
                        <span className="footer-country">United States</span>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
